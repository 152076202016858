import React, { Component, Fragment } from 'react';
import * as contentful from 'contentful';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import ProjectThumbnail from '../../components/ProjectThumbnail/ProjectThumbnail';

const stripchar = require('stripchar').StripChar;

require('./Categories.scss');

const client = contentful.createClient({
  space: 'r0uw8o7hhr9z',
  accessToken: '8b24f16378dc93e0e4f8ecaf62344c00115592aca3b9067786b00aaa4dc12ce4',
});

class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }

  componentWillReceiveProps() {
    this.fetchPosts().then(this.setPosts);
  }

  shuffleArray = (array) => {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  fetchPosts = () => client.getEntries();

  setPosts = (response) => {
    this.setState({
      posts: this.shuffleArray(
        response.items.filter(
          (item) =>
            item.sys.contentType.sys.id === 'proyecto' &&
            item.fields.categoria.some(
              (cat) =>
                stripchar
                  .RSExceptUnsAlpNum(cat.fields.titulo.toLowerCase(), '_')
                  .replace(/\s/g, '') === this.props.match.params.projectId,
            ),
        ),
      ),
    });
  };

  render() {
    const { location } = this.props;
    const { posts } = this.state;
    return (
      <Fragment>
        <Header locationView={location.pathname} />
        <div className="l-category">
          <div className="l-category__grid">
            {posts &&
              posts.map((entry, i) => (
                <ProjectThumbnail key={i} i={i} entry={entry} parent={this} />
              ))}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Categories;

import right from './assets/right.png';
import left from './assets/left.png';

import ReactImageAppear from 'react-image-appear';

import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import React, { Component, Fragment } from 'react';
import * as contentful from 'contentful';
import Markdown from 'react-markdown';
import ReactPlayer from 'react-player';
const stripchar = require('stripchar').StripChar;

// import {OBJModel} from 'react-3d-viewer';
// import antonOne from './obj/st.obj';

require("./DetailProject.scss");

const client = contentful.createClient({
  space: 'r0uw8o7hhr9z',
  accessToken: '8b24f16378dc93e0e4f8ecaf62344c00115592aca3b9067786b00aaa4dc12ce4',
});

class DetailProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: [],
      showDetailBox: false,
      tabSelected: 1,
      imageArrays: [],
      positionArray: 0,
      showNext: true,
      showLast: true,
      showSlider: false,
    };
  }

  componentDidMount() {
    this.fetchPosts().then(this.setProject);
    window.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 39:
        this.moveSlider('next');
        break;
      case 37:
        this.moveSlider('last');
        break;
      case 27:
        this.showSlider(false);
        break;
      default:
        this.showSlider(true);
    }
  };

  handleClickOutside = (event) => {
    if (this.detailProject) {
      if (!this.detailProject.contains(event.target) && this.detailProject) {
        this.showSlider(false);
      }
    }
  };

  getImageArrays = (item) => {
    const itemArray = item[0].fields.galeriaImagenes.map((image, index) => {
      return image.fields.file.url;
    });
    return itemArray;
  };

  fetchPosts = () => client.getEntries();

  setProject = (response) => {
    const { match } = this.props;

    this.setState({
      project: response.items.filter(
        (item) =>
          item.sys.contentType.sys.id === 'proyecto' &&
          stripchar.RSExceptUnsAlpNum(item.fields.titulo.toLowerCase().replace(/\s/g, ''), '_') ===
            match.params.projectId
      ),
      imageArrays: this.getImageArrays(
        response.items.filter(
          (item) =>
            item.sys.contentType.sys.id === 'proyecto' &&
            stripchar.RSExceptUnsAlpNum(
              item.fields.titulo.toLowerCase().replace(/\s/g, ''),
              '_'
            ) === match.params.projectId
        )
      ),
    });
  };

  changeTabInfo = (tabSelected) => {
    this.setState({
      tabSelected,
    });
  };

  buildVideoProject = () => {
    const { project } = this.state;
    if (project.length > 0) {
      if (project[0].fields.video) {
        return (
          <div className="l-detail-project__first-media-video">
            <ReactPlayer controls={false} width="100%" height={500} url={project[0].fields.video} />
          </div>
        );
      }
    }
  };

  buildDescriptionProject = () => {
    // descripcinProyecto
    const { project } = this.state;
    if (project.length > 0) {
      if (project[0].fields.descripcinProyecto) {
        return (
          <div className="l-detail-project__description">
            <Markdown source={project[0].fields.descripcinProyecto} />
          </div>
        );
      }
    }
  };

  buildDetailProject = () => {
    const { project } = this.state;
    let galleryImages;

    if (project.length > 0) {
      if (project[0].fields.galeriaImagenes) {
        galleryImages = project[0].fields.galeriaImagenes.map((image, index) => {
          return (
            <div className="container-image" onClick={() => this.showSlider(true, index)}>
              <ReactImageAppear
                key={index.toString()}
                src={image.fields.file.url}
                placeholderStyle={{
                  backgroundColor: 'transparent',
                }}
                animation="fadeIn"
                animationDuration="100ms"
                loaderClass="my-loader"
              />
            </div>
          );
        });
      }

      return <Fragment>{galleryImages}</Fragment>;
    } else {
      return null;
    }
  };

  showSlider = (showSlider, positionArray = 0) => {
    this.setState({
      showSlider,
      positionArray,
    });
  };

  moveSlider = (d) => {
    const { positionArray, imageArrays } = this.state;

    if (d === 'next' && positionArray < imageArrays.length - 1) {
      this.setState({
        positionArray: positionArray + 1,
        showNext: true,
      });
    } else if (d === 'last' && positionArray > 0) {
      this.setState({
        positionArray: positionArray - 1,
        showLast: true,
      });
    }
  };

  render() {
    const { project, imageArrays, positionArray, showLast, showNext, showSlider } = this.state;

    const title = project.length > 0 ? project[0].fields.titulo : '';
    return (
      <Fragment>
        <Header />
        <div className="l-detail-project">
          {showSlider && (
            <div className="l-detail-project__slider">
              <div
                className="l-detail-project__slider-container"
                ref={(el) => (this.detailProject = el)}
              >
                {showLast && (
                  <img
                    src={left}
                    alt="left"
                    className="l-detail-project__slider-last-arrow"
                    onClick={() => this.moveSlider('last')}
                  />
                )}
                <img className="slider-image" src={imageArrays[positionArray]} alt="slider" />
                {showNext && (
                  <img
                    src={right}
                    alt="right"
                    className="l-detail-project__slider-next-arrow"
                    onClick={() => this.moveSlider('next')}
                  />
                )}
              </div>
            </div>
          )}
          <h1>{title}</h1>
          <div className="l-detail-project__grid">
            {this.buildDetailProject()}
            {this.buildDescriptionProject()}
            {this.buildVideoProject()}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default DetailProject;

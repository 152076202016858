import React, { useEffect, useState, Fragment } from 'react';
import * as contentful from 'contentful';
import Markdown from 'react-markdown';

import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';

require('./Contact.scss');

const client = contentful.createClient({
  space: 'r0uw8o7hhr9z',
  accessToken: '8b24f16378dc93e0e4f8ecaf62344c00115592aca3b9067786b00aaa4dc12ce4',
});

const Contact = (props) => {
  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    client.getEntry('7Ayo3BgYVXk2oxvJbWypI2').then((entry) => {
      setContactInfo(entry.fields);
    });
  }, []);

  const { location } = props;
  return (
    <Fragment>
      <Header locationView={location.pathname} />

      <div className="l-contact">
        {contactInfo.contactText && (
          <div className="l-contact__text">
            <Markdown source={contactInfo.contactText || ''} />
          </div>
        )}
        {contactInfo.email && (
          <p className="l-contact__text">
            <a href={contactInfo.email}>{contactInfo.email}</a>
          </p>
        )}
        {contactInfo.instagram && (
          <p className="l-contact__text">
            <a href={'https://www.instagram.com/' + contactInfo.instagram}>
              @{contactInfo.instagram}
            </a>
          </p>
        )}
        {contactInfo.phone && (
          <p className="l-contact__text">
            <a href={'tel:' + contactInfo.phone}>{contactInfo.phone}</a>
          </p>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};

export default Contact;

import React, { Component } from 'react';

require('./Footer.scss');

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            default: false
        }
    }

    render() {

        return (
            <footer className="c-footer">
                <span>
                    Proudly baked by <a href="http://www.carniceria.org/" rel="noopener noreferrer" target="_blank">|carnicería</a>
                </span>
            </footer>
        );
    }
}

export { Footer };

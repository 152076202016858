import React, { Component, Fragment } from 'react';
import * as contentful from 'contentful';
import { Header } from '../../components/Header/Header';

import { Footer } from '../../components/Footer/Footer';
import ProjectThumbnail from '../../components/ProjectThumbnail/ProjectThumbnail';
var _ = require('lodash');

require('./Home.scss');

const client = contentful.createClient({
  space: 'r0uw8o7hhr9z',
  accessToken: '8b24f16378dc93e0e4f8ecaf62344c00115592aca3b9067786b00aaa4dc12ce4',
});

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }

  fetchPosts = () => client.getEntries();

  setPosts = (response) => {
    const he = response.items.filter((item) => item.sys.contentType.sys.id === 'proyecto');
    const withOrder = he.filter((item) => item.fields.order !== undefined);
    const withNoOrder = he.filter((item) => item.fields.order === undefined);
    const resultOrder = _.sortBy(withOrder, (o) => o.fields.order);

    this.setState({
      posts: resultOrder.concat(withNoOrder),
    });
  };

  render() {
    const { location } = this.props;
    const { posts } = this.state;

    return (
      <Fragment>
        <Header locationView={location.pathname} />
        <div className="l-home">
          <div className="l-home__grid">
            {posts &&
              posts.map((entry, i) => (
                <ProjectThumbnail key={i} i={i} entry={entry} parent={this} />
              ))}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Home;

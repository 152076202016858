import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as contentful from 'contentful';
import animateScrollTo from 'animated-scroll-to';

const stripchar = require('stripchar').StripChar;

require('./Header.scss');

const client = contentful.createClient({
  space: 'r0uw8o7hhr9z',
  accessToken: '8b24f16378dc93e0e4f8ecaf62344c00115592aca3b9067786b00aaa4dc12ce4',
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: [],
      posts: [],
    };
  }

  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }

  fetchPosts = () => client.getEntries();

  setPosts = (response) => {
    this.setState({
      category: response.items.filter(
        (item) => item.sys.contentType.sys.id === 'categoria' && item.fields.titulo !== 'Photo 35mm'
      ),
      posts: response.items.filter((item) => item.sys.contentType.sys.id === 'proyecto'),
    });
  };

  buildProjectsMenu = (category) => {
    const { posts } = this.state;

    const projectsList = posts
      .filter(
        (item) =>
          stripchar
            .RSExceptUnsAlpNum(item.fields.categoria[0].fields.titulo.toLowerCase(), '_')
            .replace(/\s/g, '') === category
      )
      .map((entry, index) => {
        return (
          <Link
            key={index.toString()}
            to={`/project/${stripchar.RSExceptUnsAlpNum(
              entry.fields.titulo.toLowerCase().replace(/\s/g, ''),
              '_'
            )}/`}
          >
            {entry.fields.titulo}
          </Link>
        );
      });

    return projectsList;
  };

  buildOptions = () => {
    const { category } = this.state;

    if (category) {
      const gridContent = category.map((entry, index) => {
        return (
          <li key={index.toString()}>
            <Link
              to={`/categories/${stripchar
                .RSExceptUnsAlpNum(entry.fields.titulo.toLowerCase(), '_')
                .replace(/\s/g, '')}`}
            >
              {entry.fields.titulo}
            </Link>
          </li>
        );
      });

      return gridContent;
    } else {
      return null;
    }
  };

  scrollDown() {
    /* const options = { cancelOnUserAction: false };
    animateScrollTo(window.innerHeight, options).then((scrolled) => {
      console.log('scrolled', scrolled);
    }); */
    window.scrollTo({top: window.innerHeight, behavior: 'smooth'});
    console.log('animateScrollTo', window.innerHeight, window.scrollY);
  };

  render() {
    const { locationView } = this.props;

    return (
      <div className="c-header-menu" onClick={this.scrollDown}>
        <ul className="c-header-menu-items">
          <li className="c-li-works container-dropdown">
            <Link className={` ${locationView === '/' ? '-selected' : ''}`} to="/">
              Work
            </Link>
            <div className="c-header-menu__dropdown sub">
              <ul>{this.buildOptions()}</ul>
            </div>
          </li>
          <li>
            <Link
              to={`/categories/${stripchar
                .RSExceptUnsAlpNum('Photo 35mm'.toLowerCase(), '_')
                .replace(/\s/g, '')}`}
            >
              35mm
            </Link>
          </li>
          <li>
            <Link className={`${locationView === '/contact/' ? '-selected' : ''}`} to="/contact/">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export { Header };

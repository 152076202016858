import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './views/Home/Home';
import Contact from './views/Contact/Contact';
import DetailProject from './views/DetailProject/DetailProject';
import Categories from './views/Categories/Categories';
import Landing from './components/Landing/Landing';

import './styles/index.scss';
require('../node_modules/sal.js/dist/sal.css');

const backgroundImg = [
  'background-image: url("https://media.giphy.com/media/xUA7aKCtqnlAzuIg8M/giphy.gif")',
  'background-size: cover',
  'background-position: center',
  '-webkit-text-stroke: 2px black',
];
const signatureStyle = [
  'color: #fff',
  'background: #000',
  'font-size: 50px',
  'font-family: "Nova Cut", cursive',
  'padding: 30px 10px',
  ...backgroundImg,
];
const contactStyle = ['color: #fff', 'background: #000', 'font-size: 16px'];
console.log('%cUpdated by DoubtfulBit', signatureStyle.join(';'));
console.log('%cFind us at doubtfulbit.com 💻 & @doubtfulbinarydigit 📷', contactStyle.join(';'));

const App = () => (
  <Router>
    <div className="body">
      <Landing />
      <div className="content">
        <Route exact path="/" component={Home} />
        <Route exact path="/contact/" component={Contact} />
        <Route exact path="/project/:projectId/" component={DetailProject} />
        <Route exact path="/categories/:projectId/" component={Categories} />
      </div>
    </div>
  </Router>
);

export default App;

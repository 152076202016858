import React, { useState, useEffect } from 'react';
import * as contentful from 'contentful';
import ReactImageAppear from 'react-image-appear';
import CrossfadeImage from 'react-crossfade-image';
import { Header } from '../../components/Header/Header';

import { Link } from 'react-router-dom';

import useInterval from '../../hooks/useInterval';

import Typewriter from '../Typewriter/Typewriter';
import './Landing.scss';

const client = contentful.createClient({
  space: 'r0uw8o7hhr9z',
  accessToken: '8b24f16378dc93e0e4f8ecaf62344c00115592aca3b9067786b00aaa4dc12ce4',
});

const Landing = () => {

  const [landingInfo, setLandingInfo] = useState({});

  useEffect(() => {
    client.getEntry('6kbCpogE6HxqKbpSr8zi4b').then((entry) => {
      setLandingInfo(entry.fields);
    });
  }, []);

  const imageNumber = landingInfo.images ? landingInfo.images.length : 0;
  const [index, setIndex] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const changeImage = () => {
    if (firstLoad) {
      setFirstLoad(false);
    }
    const newI = index + 1;
    if (newI < imageNumber) {
      setIndex(newI);
    } else {
      setIndex(0);
    }
  };

  useInterval(changeImage, 3000);

  return (
    <div className="landing-screen">
      <Header locationView={""}/>
      <Link to="/">
        <Typewriter title={landingInfo.pageTitle} />
        <h2>{landingInfo.subtitle}</h2>
        {landingInfo.images &&
          landingInfo.images.map(
            (image, i) =>
              index === i &&
              firstLoad && (
                <ReactImageAppear
                  src={image.fields.file.url}
                  placeholderStyle={{
                    backgroundColor: 'black',
                  }}
                  animation="fadeIn"
                  animationDuration="1000ms"
                  loaderClass="my-loader"
                  className={'current-img'}
                />
              )
          )}
        {landingInfo.images && (
          <CrossfadeImage
            src={landingInfo.images[index].fields.file.url}
            delay={0}
            duration={3000}
          />
        )}
        {landingInfo.images &&
          landingInfo.images.map((img) => <img className="hidden" src={img.fields.file.url} />)}
        {/* landingInfo.vimeoId && landingInfo.vimeoId.trim() !== '' &&(
          <div className="video-wrapper">
            <iframe
              src={`https://player.vimeo.com/video/${
                landingInfo.vimeoId
              }?background=1&controls=0&autoplay=1&muted=1&transparent=1&title=0&byline=0&portrait=0&#t=${landingInfo.videoStart ||
                '0s'}`}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        ) */}
      </Link>
      <div className="scroll" onClick={()=>window.scrollTo({top: window.innerHeight, behavior: 'smooth'})}>
        <div>SCROLL TO WORK</div>
        <svg
        className="chevron"
          id="Capa_1"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 24.71656"
        >
          <polygon
            points="35.283 0 20 15.283 4.717 0 0 4.717 20 24.717 40 4.717 35.283 0"
            fill="#fff"
          />
        </svg>
      </div>
    </div>
  );
};

export default Landing;

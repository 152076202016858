import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ProjectThumbnail.scss';
const stripchar = require('stripchar').StripChar;

const ProjectThumbnail = ({ i, entry, parent }) => {
  const {
    fields: { imagenPrincipal, titulo, tituloCorto },
  } = entry;
  const [src, setSrc] = useState(null);
  useEffect(() => {
    const { url } = imagenPrincipal.fields.file;

    const imageLoader = new Image();
    imageLoader.src = url;

    imageLoader.onload = () => {
      setSrc(url);
    };
  }, [entry]);

  return (
    <Link
      to={`/project/${stripchar.RSExceptUnsAlpNum(titulo.toLowerCase().replace(/\s/g, ''), '_')}/`}
      className="l-home__grid-box-aspect-ratio"
      key={i.toString()}
      ref={(project) => {
        parent[`project${i.toString()}`] = project;
      }}
    >
      <div className="l-home__grid-box">
        <div className="l-home__grid-box-aspect-ratio-sub-title">
          <span>{tituloCorto}</span>
        </div>
        <div
          className="l-home__grid-back-image"
          style={src ? { backgroundImage: `url(${src})` } : { backgroundColor: 'white' }}
        />
      </div>
      <span className={`l-home__grid-box-title`}>{titulo}</span>
    </Link>
  );
};

export default ProjectThumbnail;
